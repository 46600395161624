import React from "react"

import GA from "react-ga"

import AppBase from "../../util/app-base"
import DonationPageLayout from "../../components/spenden-page/donation-page-layout"

import { InternalRainbowLink } from "../../components/rainbow-link"

export default () => (
  <AppBase title="FLAGincluded - Spenden">
    <DonationPageLayout
      showPocket={false}
      leftButton={{
        enabled: false,
      }}
      labelText="Schritt 6"
      rightButton={{
        enabled: false,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2>Es ist leider etwas schiefgegangen :(</h2>
        <InternalRainbowLink
          onClick={() => {
            GA.event({
              category: "PayPal Failure",
              action: "Retry",
            })
          }}
          href="/spenden/#/zahlungsmethode"
          title="Nochmal versuchen"
          style={{ marginTop: "20px" }}
        />
      </div>
    </DonationPageLayout>
  </AppBase>
)
